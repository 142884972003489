<template>
  <modal
    name="modal-confirmacao"
    scrollable
    width="400"
    height="auto"
    style="z-index: 9999 !important"
  >
    <div style="padding: 32px">
      <h2 class="text-lef">
        Confirmar {{ editar ? "edição" : "cadastro" }} da campanha?
      </h2>
      <div class="warning-messages-container">
        <div class="d-block" style="white-space: pre; margin-top: 32px">
          {{ campanhaWarningMessage }}
        </div>
      </div>
      <div
        class="buttons-container"
        style="
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        "
      >
        <b-button
          class="w-100 text-uppercase"
          variant="success"
          @click="submitCampanhaFromModal"
          >Sim</b-button
        >
        <b-button
          class="w-100 text-uppercase"
          variant="danger"
          @click="fecharModalConfirmacao"
          >Não</b-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalConfirmacao",
  props: {
    campanhaWarningMessage: String,
    editar: Boolean
  },
  methods: {
    fecharModalConfirmacao() {
      this.$modal.hide("modal-confirmacao");
    },
    showModalConfirmacao() {
      // if(this.objetoParaEnviarNoCadastro.)
      this.$modal.show("modal-confirmacao");
    },
    submitCampanhaFromModal() {
      this.$emit("submitCampanhaFromModal");
    }
  }
};
</script>

<style></style>
