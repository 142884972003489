const presetsArr = [
  {
    name: "Confirma Consulta",
    presetFilter: [
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 4573,
        mfi_valor: "CONSULTA",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 12,
        ope_titulo: "Contém (Começa com)",
        ope_sinal: "LIKE%",
        filtro_id: 11,
        fil_tipo: "INP",
        fil_titulo: "Classe do Exame: Nome",
        fil_campo: "exame_classe_nome",
        fil_descricao: "Nome da Classe do Exame",
        fil_conteudo: "exame_classe_nome",
        fil_mascara: "string",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      },
      {
        hierarquia: 0,
        listaDeFiltros: [
          {
            hierarquia: 1,
            listaDeFiltros: [
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 4574,
                mfi_valor: "Monday",
                mfi_parenteses_inicio: "",
                mfi_parenteses_fim: null,
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 2,
                ope_titulo: "Diferente",
                ope_sinal: "<>",
                filtro_id: 10,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Nome do Dia [EN: Monday, ...]",
                fil_campo: "agenda_dia_nome",
                fil_descricao: "Dia da semana em inglês",
                fil_conteudo: "agenda_dia_nome",
                fil_mascara: "string",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: null,
                contexto_id: null,
                con_titulo: null,
                con_mascara: null,
                con_grupo: null,
                con_modificador: null
              },
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 4575,
                mfi_valor: "a",
                mfi_parenteses_inicio: null,
                mfi_parenteses_fim: ")",
                mfi_e_ou: "OR",
                mfi_valor_2: null,
                operador_id: 5,
                ope_titulo: "Contém (Em qualquer posição)",
                ope_sinal: "%LIKE%",
                filtro_id: 2,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Data EN",
                fil_campo: "agenda_data_en",
                fil_descricao: "Dia após o envio de mensagens",
                fil_conteudo: "agenda_data_en",
                fil_mascara: "date",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: "yyyy-mm-dd",
                contexto_id: 3,
                con_titulo: "Data depois de Amanhã [EN] [AAAA-MM-DD]",
                con_mascara: "Y-m-d",
                con_grupo: "DATA",
                con_modificador: 2,
                isContextFunction: true
              }
            ],
            isContextFunction: true
          },
          {
            hierarquia: 1,
            listaDeFiltros: [
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 4576,
                mfi_valor: "Monday",
                mfi_parenteses_inicio: "",
                mfi_parenteses_fim: null,
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 1,
                ope_titulo: "Igual",
                ope_sinal: "=",
                filtro_id: 10,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Nome do Dia [EN: Monday, ...]",
                fil_campo: "agenda_dia_nome",
                fil_descricao: "Dia da semana em inglês",
                fil_conteudo: "agenda_dia_nome",
                fil_mascara: "string",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: null,
                contexto_id: null,
                con_titulo: null,
                con_mascara: null,
                con_grupo: null,
                con_modificador: null
              },
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 4577,
                mfi_valor: "a",
                mfi_parenteses_inicio: null,
                mfi_parenteses_fim: "))",
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 5,
                ope_titulo: "Contém (Em qualquer posição)",
                ope_sinal: "%LIKE%",
                filtro_id: 2,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Data EN",
                fil_campo: "agenda_data_en",
                fil_descricao: "Dia após o envio de mensagens",
                fil_conteudo: "agenda_data_en",
                fil_mascara: "date",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: "yyyy-mm-dd",
                contexto_id: 9,
                con_titulo: "Data de 3 dias depois [EN] [AAAA-MM-DD]",
                con_mascara: "Y-m-d",
                con_grupo: "DATA",
                con_modificador: 3,
                isContextFunction: true
              }
            ],
            isContextFunction: true
          }
        ],
        isContextFunction: true
      },
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 4578,
        mfi_valor: "A",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_valor_2: null,
        operador_id: 1,
        ope_titulo: "Igual",
        ope_sinal: "=",
        filtro_id: 3,
        fil_tipo: "INP",
        fil_titulo: "Agenda: Status da Confirmação",
        fil_campo: "agenda_status",
        fil_descricao: "Situação do agendamento",
        fil_conteudo: "agenda_status",
        fil_mascara: "string",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      }
    ]
  },
  {
    name: "Notifica Faltosos",
    presetFilter: [
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 416,
        mfi_valor: "0",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 1,
        ope_titulo: "Igual",
        ope_sinal: "=",
        filtro_id: 12,
        fil_tipo: "INP",
        fil_titulo: "Exame: Quantidade",
        fil_campo: "quantidade_exames",
        fil_descricao: "Quantidade de Exames",
        fil_conteudo: "quantidade_exames",
        fil_mascara: "int",
        fil_tipo_dado: "int",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      },
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 418,
        mfi_valor: "",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_valor_2: null,
        operador_id: 5,
        ope_titulo: "Contém (Em qualquer posição)",
        ope_sinal: "%LIKE%",
        filtro_id: 2,
        fil_tipo: "INP",
        fil_titulo: "Agenda: Data EN",
        fil_campo: "agenda_data_en",
        fil_descricao: "Dia após o envio de mensagens",
        fil_conteudo: "agenda_data_en",
        fil_mascara: "date",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: "yyyy-mm-dd",
        contexto_id: 4,
        con_titulo: "Data de Ontem [EN] [AAAA-MM-DD]",
        con_mascara: "Y-m-d",
        con_grupo: "DATA",
        con_modificador: -1,
        isContextFunction: true
      }
    ]
  },
  {
    name: "Confirma Agenda Amanhã",
    presetFilter: [
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 4606,
        mfi_valor: "",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 12,
        ope_titulo: "Contém (Começa com)",
        ope_sinal: "LIKE%",
        filtro_id: 2,
        fil_tipo: "INP",
        fil_titulo: "Agenda: Data EN",
        fil_campo: "agenda_data_en",
        fil_descricao: "Dia após o envio de mensagens",
        fil_conteudo: "agenda_data_en",
        fil_mascara: "date",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: "yyyy-mm-dd",
        contexto_id: 3,
        con_titulo: "Data de Amanhã [EN] [AAAA-MM-DD]",
        con_mascara: "Y-m-d",
        con_grupo: "DATA",
        con_modificador: 1,
        isContextFunction: true
      },
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 4607,
        mfi_valor: "CONSULTA",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 5,
        ope_titulo: "Contém (Em qualquer posição)",
        ope_sinal: "%LIKE%",
        filtro_id: 11,
        fil_tipo: "INP",
        fil_titulo: "Classe do Exame: Nome",
        fil_campo: "exame_classe_nome",
        fil_descricao: "Nome da Classe do Exame",
        fil_conteudo: "exame_classe_nome",
        fil_mascara: "string",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      },
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 4608,
        mfi_valor: "N",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_valor_2: null,
        operador_id: 2,
        ope_titulo: "Diferente",
        ope_sinal: "<>",
        filtro_id: 3,
        fil_tipo: "INP",
        fil_titulo: "Agenda: Status da Confirmação",
        fil_campo: "agenda_status",
        fil_descricao: "Situação do agendamento",
        fil_conteudo: "agenda_status",
        fil_mascara: "string",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      }
    ]
  },
  {
    name: "Confirma Consulta 24h",
    presetFilter: [
      {
        hierarquia: 0,
        listaDeFiltros: [
          {
            hierarquia: 1,
            listaDeFiltros: [
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 2124,
                mfi_valor: "",
                mfi_parenteses_inicio: "",
                mfi_parenteses_fim: null,
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 12,
                ope_titulo: "Contém (Começa com)",
                ope_sinal: "LIKE%",
                filtro_id: 2,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Data EN",
                fil_campo: "agenda_data_en",
                fil_descricao: "Dia após o envio de mensagens",
                fil_conteudo: "agenda_data_en",
                fil_mascara: "date",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: "yyyy-mm-dd",
                contexto_id: 3,
                con_titulo: "Data depois de Amanhã [EN] [AAAA-MM-DD]",
                con_mascara: "Y-m-d",
                con_grupo: "DATA",
                con_modificador: 2,
                isContextFunction: true
              },
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 2125,
                mfi_valor: "MONDAY",
                mfi_parenteses_inicio: null,
                mfi_parenteses_fim: ")",
                mfi_e_ou: "OR",
                mfi_valor_2: null,
                operador_id: 6,
                ope_titulo: "Não Contém (Em nenhuma posição)",
                ope_sinal: "%NOT_LIKE%",
                filtro_id: 10,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Nome do Dia [EN: Monday, ...]",
                fil_campo: "agenda_dia_nome",
                fil_descricao: "Dia da semana em inglês",
                fil_conteudo: "agenda_dia_nome",
                fil_mascara: "string",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: null,
                contexto_id: null,
                con_titulo: null,
                con_mascara: null,
                con_grupo: null,
                con_modificador: null
              }
            ],
            isContextFunction: true
          },
          {
            hierarquia: 1,
            listaDeFiltros: [
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 2126,
                mfi_valor: "",
                mfi_parenteses_inicio: "",
                mfi_parenteses_fim: null,
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 12,
                ope_titulo: "Contém (Começa com)",
                ope_sinal: "LIKE%",
                filtro_id: 2,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Data EN",
                fil_campo: "agenda_data_en",
                fil_descricao: "Dia após o envio de mensagens",
                fil_conteudo: "agenda_data_en",
                fil_mascara: "date",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: "yyyy-mm-dd",
                contexto_id: 8,
                con_titulo: "Data de 3 dias depois [EN] [AAAA-MM-DD]",
                con_mascara: "Y-m-d",
                con_grupo: "DATA",
                con_modificador: 3,
                isContextFunction: true
              },
              {
                hierarquia: 2,
                listaDeFiltros: [],
                modelo_filtro_id: 2127,
                mfi_valor: "MONDAY",
                mfi_parenteses_inicio: null,
                mfi_parenteses_fim: "))",
                mfi_e_ou: "AND",
                mfi_valor_2: null,
                operador_id: 5,
                ope_titulo: "Contém (Em qualquer posição)",
                ope_sinal: "%LIKE%",
                filtro_id: 10,
                fil_tipo: "INP",
                fil_titulo: "Agenda: Nome do Dia [EN: Monday, ...]",
                fil_campo: "agenda_dia_nome",
                fil_descricao: "Dia da semana em inglês",
                fil_conteudo: "agenda_dia_nome",
                fil_mascara: "string",
                fil_tipo_dado: "varchar",
                fil_mascara_formato: null,
                contexto_id: null,
                con_titulo: null,
                con_mascara: null,
                con_grupo: null,
                con_modificador: null
              }
            ],
            isContextFunction: true
          }
        ],
        isContextFunction: true
      },
      {
        hierarquia: 0,
        listaDeFiltros: [],
        modelo_filtro_id: 2128,
        mfi_valor: "A",
        mfi_parenteses_inicio: null,
        mfi_parenteses_fim: null,
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 1,
        ope_titulo: "Igual",
        ope_sinal: "=",
        filtro_id: 3,
        fil_tipo: "INP",
        fil_titulo: "Agenda: Status da Confirmação",
        fil_campo: "agenda_status",
        fil_descricao: "Situação do agendamento",
        fil_conteudo: "agenda_status",
        fil_mascara: "string",
        fil_tipo_dado: "varchar",
        fil_mascara_formato: null,
        contexto_id: null,
        con_titulo: null,
        con_mascara: null,
        con_grupo: null,
        con_modificador: null
      },
      {
        hierarquia: 0,
        listaDeFiltros: [
          {
            hierarquia: 1,
            listaDeFiltros: [],
            modelo_filtro_id: 2129,
            mfi_valor: "",
            mfi_parenteses_inicio: "",
            mfi_parenteses_fim: null,
            mfi_e_ou: "OR",
            mfi_valor_2: null,
            operador_id: 1,
            ope_titulo: "Igual",
            ope_sinal: "=",
            filtro_id: 6,
            fil_tipo: "INP",
            fil_titulo: "Prestador: Código",
            fil_campo: "prestador_id",
            fil_descricao: "Médico que aplicou o procedimento",
            fil_conteudo: "prestador_id",
            fil_mascara: "int",
            fil_tipo_dado: "int",
            fil_mascara_formato: null,
            contexto_id: null,
            con_titulo: null,
            con_mascara: null,
            con_grupo: null,
            con_modificador: null
          },
          {
            hierarquia: 1,
            listaDeFiltros: [],
            modelo_filtro_id: 2130,
            mfi_valor: "",
            mfi_parenteses_inicio: null,
            mfi_parenteses_fim: null,
            mfi_valor_2: null,
            operador_id: 1,
            ope_titulo: "Igual",
            ope_sinal: "=",
            filtro_id: 6,
            fil_tipo: "INP",
            fil_titulo: "Prestador: Código",
            fil_campo: "prestador_id",
            fil_descricao: "Médico que aplicou o procedimento",
            fil_conteudo: "prestador_id",
            fil_mascara: "int",
            fil_tipo_dado: "int",
            fil_mascara_formato: null,
            contexto_id: null,
            con_titulo: null,
            con_mascara: null,
            con_grupo: null,
            con_modificador: null
          }
        ],
        isContextFunction: true
      }
    ]
  }
];
export default presetsArr;
