<template>
  <modal name="modal-html-preview" scrollable width="80%" height="auto">
    <div class="modal-html-container">
      <!-- close button -->
      <b-button @click="close" class="btn-close">
        <i class="fas fa-times"></i>
      </b-button>
      <!-- <h3>Preview</h3> -->
      <div v-html="htmlImagesFit"></div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalHtmlPreview",
  props: {
    html: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showPreview: false
    };
  },

  methods: {
    close() {
      this.$modal.hide("modal-html-preview");
    }
  },
  computed: {
    htmlImagesFit() {
      if (!this.html) {
        return "";
      }
      return this.html.replace(/<img/g, '<img style="max-width: 100%"');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-html-container {
  padding: 32px !important;
  overflow: hidden;
  position: relative;
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    color: #000;
    font-size: 24px;
    &:hover {
      background: transparent !important;
      color: #000;
    }
  }
  img {
    max-width: 100%;
  }
}
</style>
