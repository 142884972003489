<template>
  <div class="webhook-options scroll-container p-3">
    <form @submit.prevent="submitForm">
      <b-row>
        <b-col cols="12">
          <b-form-group label="URL do Webhook" label-for="url-input">
            <b-form-input
              id="url-input"
              v-model="formData.url"
              @change="updateJson"
              placeholder="Digite a URL do Webhook"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Método HTTP" label-for="method-input">
            <b-form-select
              id="method-input"
              v-model="formData.method"
              :options="httpMethods"
              @change="updateJson"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Enviar como" label-for="sendAs-input">
            <b-form-select
              id="sendAs-input"
              v-model="formData.sendAs"
              :options="sendAsMethods"
              @change="updateJson"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2">
          <b-form-group label="Quantidade por lote" label-for="batchSize-input">
            <b-form-input
              id="batchSize-input"
              v-model.number="formData.batchSize"
              type="number"
              min="1"
              placeholder="Digite a quantidade"
              @change="updateJson"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Content-Type" label-for="contentType-input">
            <b-form-select
              id="contentType-input"
              v-model="formData.contentType"
              :options="contentTypes"
              :disabled="!headersIsValidJson"
              @change="updateHeaders"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="headers" label-for="headers-input">
            <b-form-textarea
              id="headers-input"
              v-model="formData.headers"
              @change="formatJsonFormHeaders"
              @focus="handleFocus($event)"
              @focusout="formatJsonFormFormat"
              :state="headersIsValidJson"
              placeholder="Use {\{placeholder}} para campos dinâmicos"
              rows="4"
              style="min-height: 100px"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Formato dos Dados" label-for="format-input">
            <b-form-textarea
              id="format-input"
              v-model="formData.format"
              placeholder="Use {\{placeholder}} para campos dinâmicos"
              rows="4"
              @change="formatJsonFormFormat"
              @focusout="formatJsonFormFormat"
              @focus="handleFocus($event)"
              :state="formatIsValidJson"
              style="min-height: 100px"
              required
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="!formatIsValidJson">
              Formato JSON inválido.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WebhookOptions",
  components: {},
  data() {
    return {
      formData: {
        url: "",
        method: "POST",
        batchSize: 1,
        headers: '{"Content-Type": "application/json"}',
        format: "{}",
        formatConfig: "",
        sendAs: "params",
        contentType: "application/json"
      },
      httpMethods: ["POST", "PUT", "PATCH", "DELETE"],
      sendAsMethods: [
        { value: "params", text: "Parâmetros da consulta(query)" },
        { value: "data", text: "Parâmetros da requisição(data)" }
      ],
      contentTypes: [
        { value: "application/json", text: "application/json" },
        { value: "application/xml", text: "application/xml" },
        { value: "text/plain", text: "text/plain" },
        {
          value: "application/x-www-form-urlencoded",
          text: "application/x-www-form-urlencoded"
        }
      ],
      formatIsValidJson: true,
      headersIsValidJson: true,
      parsedJson: null
    };
  },
  methods: {
    async submitForm() {
      const dataToSend = this.construirAxiosRequestConfig();

      try {
        const response = await axios.post("YOUR_BACKEND_URL_HERE", dataToSend);
        alert("Configurações enviadas com sucesso.");
      } catch (error) {
        console.error("Erro ao enviar configurações:", error);
        alert("Falha ao enviar configurações.");
      }
    },
    updateHeaders() {
      try {
        const headers = JSON.parse(this.formData.headers);
        headers["Content-Type"] = this.formData.contentType;
        this.formData.headers = JSON.stringify(headers);
        this.headersIsValidJson = true;
        this.formatJsonFormHeaders();
      } catch (e) {
        this.headersIsValidJson = false;
      }
      this.json["Webhook"] = JSON.stringify(this.construirAxiosRequestConfig());
    },
    updateJson() {
      if (this.formData.batchSize < 1) {
        this.formData.batchSize = 1;
      }
      this.json["Webhook"] = JSON.stringify(this.construirAxiosRequestConfig());
    },
    formatJsonFormFormat() {
      try {
        this.parsedJson = JSON.parse(this.formData.format);
        // this.formData.format = JSON.stringify(this.parsedJson, null, 2);
        this.formatIsValidJson = true;
      } catch (e) {
        this.formatIsValidJson = false;
      }
      this.updateJson();
    },
    formatJsonFormHeaders() {
      try {
        this.parsedJson = JSON.parse(this.formData.headers);
        // this.formData.headers = JSON.stringify(this.parsedJson, null, 2);
        this.headersIsValidJson = true;
      } catch (e) {
        this.headersIsValidJson = false;
      }
      this.updateJson();
    },
    construirAxiosRequestConfig() {
      return {
        requestConfig: {
          headers: JSON.parse(this.formData.headers),
          method: this.formData.method,
          url: this.formData.url
        },
        sendConfig: {
          sendAs: this.formData.sendAs,
          batchSize: this.formData.batchSize,
          format: this.formData.format ? this.formData.format : "{}",
          formatConfig: this.formData.formatConfig
            ? this.formData.formatConfig
            : "{}"
        }
      };
    },
    preencherForm(objJson) {
      this.formData = {
        url: objJson?.requestConfig?.url || "",
        method: objJson?.requestConfig?.method || "POST",
        batchSize: objJson?.sendConfig?.batchSize || 1,
        headers: JSON.stringify(
          objJson?.requestConfig?.headers || {
            "Content-Type": "application/json"
          }
        ),
        format: objJson?.sendConfig?.format || "{}",
        formatConfig: objJson?.sendConfig?.formatConfig || "",
        sendAs: objJson?.sendConfig?.sendAs || "data",
        contentType:
          objJson?.requestConfig?.headers["Content-Type"] || "application/json"
      };
      this.formatJsonFormHeaders();
      this.formatJsonFormFormat();
    },
    handleFocus(event) {
      this.$emit("focused", event.target.id);
    }
  },
  created() {
    const objJson = JSON.parse(this.json["Webhook"]);
    this.preencherForm(objJson);
  },
  props: {
    json: {
      required: true
    }
  }
};
</script>

<style scoped>
.webhook-options {
  margin: 0 auto;
}
.scroll-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 15px; /* Evita que o conteúdo fique escondido pela barra de rolagem */
}
</style>
