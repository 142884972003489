import { render, staticRenderFns } from "./ModalCadastroCampanha.vue?vue&type=template&id=23dea9e9&scoped=true&"
import script from "./ModalCadastroCampanha.vue?vue&type=script&lang=js&"
export * from "./ModalCadastroCampanha.vue?vue&type=script&lang=js&"
import style0 from "./ModalCadastroCampanha.vue?vue&type=style&index=0&id=23dea9e9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23dea9e9",
  null
  
)

export default component.exports