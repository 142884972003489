<script>
export default {
  name: "TemplateWppVariaveis",
  components: {},
  props: {
    value: {
      type: Object
    },
    optionsLabel: {
      type: String
    },
    optionSelected: {
      type: Object
    },
    options: {
      type: Array
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<template>
  <div>
    <div
      v-for="component in value.components"
      :key="component.name"
      class="text-left pb-5"
    >
      <p v-if="component.type === 'HEADER'" class="font-weight-bold">
        {{ component.text }}
      </p>
      <div v-else-if="component.type === 'BODY'" style="white-space: pre-line">
        {{ component.text }}
      </div>
      <p v-else>
        {{ component.text }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
